@tailwind base;
@tailwind components;
@tailwind utilities;

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../Assets/Fonts/montserrat-v25-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../Assets/Fonts/montserrat-v25-latin-regular.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local(''),
    url('../Assets/Fonts/montserrat-v25-latin-500.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../Assets/Fonts/montserrat-v25-latin-500.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local(''),
    url('../Assets/Fonts/montserrat-v25-latin-600.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../Assets/Fonts/montserrat-v25-latin-600.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('../Assets/Fonts/montserrat-v25-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../Assets/Fonts/montserrat-v25-latin-700.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

::-webkit-scrollbar {
  inline-size: 5px;
  block-size: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #91caff;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #91caff;
}

body {
  font-family: 'Montserrat', sans-serif;
  overflow-y: hidden;
  background-color: white;
}


.visually-hidden {
  position: absolute;
  inline-size: 1px;
  block-size: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.site-logo {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.active {
  background-color: #3b3a3a;
}

.skeleton {
  animation: skeleton 1s linear alternate;
  animation-iteration-count: infinite;
}

@keyframes skeleton {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.active::after {
  content: '';
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  block-size: 100%;
  inline-size: 2px;
  background-color: #fff;
}

.search-input {
  cursor: pointer;
  background-image: url("../Assets/Images/seach-icon.svg");
  background-repeat: no-repeat;
  background-position: left;
  background-position-x: 12px;
}

.login-bg {
  background-image: url("../Assets/Images/bg-login.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}


.chabox-img {
  animation-name: cashbox;
  animation-duration: 0.4s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}

@keyframes cashbox {
  0% {
    transform: scale(0.99);
  }

  100% {
    transform: scale(1);
  }
}

.mxik-product-render-list:nth-child(2n) {
  background-color: #F2F2F2;
}

.ant-picker-ok>button {
  background-color: blue;
}

.ant-picker-input>input {
  text-align: center;
}

.ant-select-selector {
  inline-size: 100%;
  block-size: auto !important;
  /* padding: 2px 11px !important; */
}

.notificationSelect>div {
  padding: 1px 0px !important;
}

.ant-select-selection-search-input {
  margin-block-start: 2px !important;
}

/* .MuiInputBase-inputTypeSearch {
  border: none !important;
  padding: 9.5px 14px !important;
} */

.MuiOutlinedInput-root {
  border-radius: 8px !important;
}

.ant-btn-primary {
  background-color: #3B82F6 !important;
}

:where(.css-dev-only-do-not-override-1n7nwfa).ant-menu-dark {
  background: #001529;
}

:where(.css-dev-only-do-not-override-1n7nwfa).ant-menu-inline-collapsed {
  inline-size: 100% !important;
}

:where(.css-dev-only-do-not-override-1n7nwfa).ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
  padding-inline: 18px !important;
}

:where(.css-dev-only-do-not-override-1n7nwfa).ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title,
:where(.css-dev-only-do-not-override-1n7nwfa).ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
  block-size: 50px;
}

.canvasjs-chart-credit {
  display: none !important;
}